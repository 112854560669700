.questNine__style {
  background-color: rgb(171, 125, 185);
  min-height: 100vh;
  color: white;
  fill: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.backNextButtons {
  display: flex;
  font-family: Avenir;
}

.questNine__style > input {
  font-size: 16px;
  margin-bottom: 40px;
  font-family: Avenir;
}

.textStyle9 {
  width: 80vw;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: Avenir;

  line-height: 160%;
}

.uvsApp__nextButton {
  cursor: pointer;
}
