.questOne__style {
  background-color: rgb(32, 56, 72);
  height: 100vh;

  color: white;
  fill: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.textStyle1 {
  width: 80vw;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: Avenir;
  line-height: 160%;
}

.uvsApp__nextButton {
  cursor: pointer;
  font-family: Avenir;
}
