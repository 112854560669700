.cPage__container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15vw;
}
.cPageLegend__container {
  display: flex;
  justify-content: center;
  column-gap: 6.41vw;
  margin-top: 5vw;
  padding-top: 1vw;
  padding-bottom: 2vw;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  width: 100vw;
}
.cPage__circlesContainer {
  display: flex;
  justify-content: center;
  gap: 5.31vw 15.559vw;
  margin-top: 8vw;
  margin-bottom: 8vw;
  flex-wrap: wrap;
}
.headphones {
  color: #2972ae;
}

.eye {
  color: #e0dcdc;
}
.eyeRed {
  color: #db3529;
}
.play {
  color: #16463b;
}

@media screen and (min-width: 768px) {
  .cPage__circlesContainer {
    gap: 6vw 12.547vw;
  }
  .cPageLegend__container {
    column-gap: 5.208vw;
    margin-top: 2.344vw;
    margin-bottom: -2.734vw;
  }
  .cPage__container {
    margin-bottom: 6.409vw;
  }
}

@media screen and (min-width: 1200px) {
  .cPage__circlesContainer {
    gap: 6vw 8.802vw;
  }
  .cPageLegend__container {
    column-gap: 7.448vw;
    margin-top: 5.99vw;
    margin-bottom: -2.734vw;
  }
  .cPage__container {
    margin-bottom: 3.409vw;
  }
}
