.circles__container {
  width: 100vw;
  margin-top: -56vw;
  position: relative;
}
.circles__circles {
  width: 15.385vw;
  height: 15.385vw;
  border-radius: 50%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.circles__text {
  font-family: "C90Degree";
  font-weight: 900;
  color: #f3f3f3;
  text-shadow: 0.1px 0.3px, 0.2px 0.6px #f3f3f3;
  font-size: 3.077vw;
  text-align: center;
  width: 13vw;
  line-height: 2.521vw;
}
@media screen and (min-width: 768px) {
  .circles__container {
    height: 51.2vw;
    z-index: 1;
  }
  .circles__circles {
    width: 8.203vw;
    height: 8.203vw;
    z-index: 3;
  }
  .circles__text {
    font-size: 1.4vw;
    width: 6vw;
    line-height: 1.1vw;
  }
}
