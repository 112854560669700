.questSeven__style {
  background-color: rgb(212, 75, 63);
  min-height: 100vh;
  color: white;
  fill: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.backNextButtons {
  display: flex;
}

.questSeven__style > input {
  font-size: 16px;
  font-family: Avenir;
}

.textStyle7 {
  width: 80vw;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: Avenir;

  line-height: 160%;
}

.uvsApp__nextButton {
  cursor: pointer;
  font-family: Avenir;
}
