.iIt__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vw;
  min-height: 250vw;
}

.revealMe {
  background-color: #2b61d5;
  color: #f3f3f3;
  border-radius: 5.128vw;
  height: 10.118vw;
  width: 44.687vw;
  border: none;
  font-size: 3.333vw;
  font-family: "C90Degree";
  font-weight: 700;
  margin-bottom: 2.959vw;
  pointer-events: none;
}
.iIt__bioCv {
  display: flex;
  gap: 5.641vw;
}
.bio__x {
  margin-bottom: 1vw;
  margin-top: 1vw;
  cursor: pointer;
  width: 80%;
}

.bio__text {
  width: 75vw;
  line-height: 8.205vw;
  font-size: 3.077vw;
}

.iIt__bio {
  height: 9.487vw;
  width: 9.487vw;
  border-radius: 50%;
  background-color: #aecbc7;
  position: relative;
  cursor: pointer;
  z-index: 5;
}
.bio__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 10.12vw;
  margin-bottom: 24.359vw;
}

.yiseulSponge {
  width: 69.487vw;
  height: 38.974vw;
  margin-bottom: 10vw;
}
.cv__imageContainer > img {
  margin-left: -3vw;
  width: 74.872vw;
  margin-bottom: 10.256vw;
}

.iIt__cv {
  height: 9.487vw;
  width: 9.487vw;
  border-radius: 50%;
  background-color: #ddc73e;
  position: relative;
  cursor: pointer;
  display: none;
}
.iIt__circleText {
  font-family: "C90Degree";
  font-weight: 900;
  color: white;
  text-shadow: 0.1px 0.3px, 0.2px 0.6px white;
  position: absolute;
  bottom: 34%;
  left: 30%;
  font-size: 2vw;
}
.iIt__topContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .iIt__container {
    margin-top: unset;
    flex-direction: column;
    align-items: center;
    min-height: 125vw;
  }
  .revealMe {
    margin-top: 2.5vw;
    border-radius: 2.604vw;
    width: 36.068vw;
    height: 8.167vw;
    font-size: 2.865vw;
  }
  .iIt__bio {
    width: 7.552vw;
    height: 7.552vw;
  }
  .iIt__cv {
    width: 7.552vw;
    height: 7.552vw;
  }
  .yiseulSponge {
    width: 52.995vw;
    height: 29.818vw;
    margin-bottom: 6.51vw;
  }
  .bio__text {
    width: 79.167vw;
    line-height: 4.167vw;
    font-size: 1.823vw;
  }
  .bio__container {
    margin-left: unset;
  }
  .iIt__topContent {
    flex-direction: row;
    flex-wrap: wrap;
    /* align-items: center; */
    justify-content: center;
  }
  #iItPageTitle {
    width: 100vw;
    text-align: center;
  }
  .iIt__bioCv {
    margin-left: 6vw;
    gap: 0.521vw;
    z-index: 5;
  }
  .cv__imageContainer {
    margin-left: 2vw;
  }
  .cv__imageContainer > img {
    width: 69.531vw;
    margin-left: unset;
    margin-bottom: 6.51vw;
  }
}
@media screen and (min-width: 1200px) {
  .iIt__container {
    min-height: 115vw;
  }
  .revealMe {
    border-radius: 1.4vw;
    height: 4.4vw;
    width: 19vw;
    font-size: 1.5vw;
    margin-left: 35vw;
  }
  .iIt__bioCv {
    margin-left: 20vw;
    gap: 0.5vw;
  }
  .iIt__bio {
    height: 5vw;
    width: 5vw;
  }
  .iIt__cv {
    height: 5vw;
    width: 5vw;
  }
  .iIt__circleText {
    font-size: 1.4vw;
  }
  .bio__container {
    flex-direction: row;
    column-gap: 7.344vw;
    margin-top: 20vw;
    margin-left: -20vw;
  }
  .bio__container > svg {
    width: 5vw;
  }
  .yiseulSponge {
    width: 29.115vw;
    height: 16.406vw;
    margin-bottom: unset;
  }
  .bio__text {
    width: 45.833vw;
    line-height: 1.667;
    font-size: 0.938vw;
  }
  .cv__imageContainer {
    width: 100vw;
    display: flex;
    column-gap: 1.4vw;
    margin-left: -11.4vw;
  }
  .cv__imageContainer > img {
    width: 27.813vw;
  }
}
