.modal1Container__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 120vh;
  margin-top: -35vw;
  background-color: rgb(231, 227, 227);
  opacity: 0.95;
  position: fixed;
  z-index: 10;
  cursor: pointer;
}

.modal1__container {
  font-family: "C90Degree";
  font-weight: 900;
  color: #16463b;
  text-shadow: 0.1px 0.3px, 0.2px 0.6px #16463b;
  font-size: 4.8vw;
  width: 72.821vw;
  height: 65vw;
  border: 2.051vw solid #d23021;
  background-color: white;
  border-radius: 4vw;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.modal1__textContainer {
  display: flex;
  flex-direction: column;
  row-gap: 1vw;
}
.small {
  font-size: 2.7vw;
  line-height: 3.5vw;
  /* margin-bottom: 0.8vw; */
}
#modal2 {
  border: 3.8vw solid #d7bf4c;
  border-radius: 40vw;
  height: 35vw;
}
/* #modal2 font-size changes the button text and modal2__question changes the question text */
.modal2__question {
  font-size: 3.846vw;
  letter-spacing: 0.385vw;
  line-height: 7.179vw;
}
.modal2__choices {
  display: flex;
  margin-top: 5vw;
  justify-content: center;
  gap: 28vw;
}
.modal2__circles {
  height: 4vw;
  width: 4vw;
  border-radius: 50%;
  background-color: #4b5c28;
}
/* blueCircle__container is actually used for both buttons */
.blueCircle__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 1.5vw;
}
#modal2BlueCircle {
  background-color: #95b2d5;
}

.modal3 > svg {
  width: 95vw;
  height: 95vw;
}
.modal4__svg > svg {
  width: 90vw;
  height: 90vw;
}
@media screen and (min-width: 768px) {
  .modal1Container__container {
    margin-top: -15vw;
  }
  .modal1__container {
    width: 57.943;
    height: 49.87vw;
  }
  .modal1__textContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.5vw;
  }
  #modal2 {
    width: 54.948vw;
    height: 29.036vw;
    border: 1.953vw solid #d7bf4c;
    border-radius: 39.063vw;
    letter-spacing: 0.313vw;
    font-size: 2.474vw;
  }
  .modal2__question {
    font-size: 3.125vw;
    line-height: 5.859vw;
  }
  .modal2__circles {
    width: 2.474vw;
    height: 2.474vw;
  }
  .modal2__choices {
    gap: 20.313vw;
  }
}

@media screen and (min-width: 1200px) {
  .modal1Container__container {
    height: 120vh;
    margin-top: -15vw;
  }

  .modal1__container {
    width: 36.458vw;
    height: 21.875vw;
    border: 0.781vw solid #d23021;
    border-radius: 1.042vw;
    font-size: 1.723vw;
  }
  .modal1__textContainer {
    display: flex;
    flex-direction: column;
    row-gap: 0.1vw;
  }
  .small {
    font-size: 0.942vw;
    line-height: 1.7vw;
  }

  .modal3 > svg {
    width: 45vw;
    height: 45vw;
  }
  .modal4__svg > svg {
    width: 36vw;
    height: 36vw;
  }
  #modal2 {
    border: 1.4vw solid #d7bf4c;
    border-radius: 40vw;
    height: 19.271vw;
    width: 35.729vw;
    font-size: 1.823vw;
  }
  .modal2__question {
    font-size: 2.083vw;
    line-height: 3.906vw;
  }
  .modal2__choices {
    margin-top: 1vw;
    gap: 12.083vw;
  }
  .modal2__circles {
    height: 1.563vw;
    width: 1.563vw;
  }
  .modal2__circles > span {
    position: relative;
    top: 4vw;
    right: 2vw;
  }
}
