@keyframes fadeIn {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}
a {
  text-decoration: none;
}

.home__container {
  /* min-height: 70vh; */
  margin-top: 10vw;
  margin-bottom: 18.718vw;
  animation: fadeIn;
  animation-duration: 2.4s;
}

.circle {
  background-color: #b9b2b24d;
  height: 26.4vw;
  width: 26.4vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circleGroup {
  display: flex;
  flex-flow: row wrap;
  gap: 38.2vw 12.1vw;
  justify-content: center;
}

.circle__text {
  font-family: "C90Degree";
  font-weight: 900;
  color: #16463b;
  text-shadow: 0.1px 0.3px, 0.2px 0.6px #16463b;
  font-size: 4.36vw;
  letter-spacing: 0.095vw;
}
@media screen and (min-width: 768px) {
  .home__container {
    margin-top: 6.51vw;
    margin-bottom: 11.328vw;
  }
  .circleGroup {
    gap: 12.24vw 16.667vw;
  }

  .circle {
    width: 23.47vw;
    height: 23.47vw;
  }
  .circle__text {
    font-size: 3.906vw;
    letter-spacing: 0.086vw;
  }
}
@media screen and (min-width: 1024px) {
  .home__container {
    margin-top: 8.984vw;
    margin-bottom: 8.767vw;
  }
  .circleGroup {
    gap: 15.527vw 23.926vw;
  }

  .circle {
    width: 21.484vw;
    height: 21.484vw;
  }
  .circle__text {
    font-size: 2.93vw;
    letter-spacing: 0.064vw;
  }
}

@media screen and (min-width: 1200px) {
  .home__container {
    margin-top: 6.25vw;
    margin-left: 24.688vw;
    margin-right: 24.688vw;
    margin-bottom: 3.594vw;
  }
  .circleGroup {
    gap: 9.219vw 5.729vw;
    justify-content: center;
  }
  .circle {
    height: 13.02vw;
    width: 13.02vw;
  }
  .circle__text {
    font-size: 1.562vw;
    letter-spacing: 0.0344vw;
  }
}

/* @media screen and (min-width: 1800px) {
  .home__container {
    margin-bottom: 1vw;
  }
} */
