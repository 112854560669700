.appThankYou__style {
  background-color: rgb(32, 56, 72);
  height: 100vh;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.textStyleThankYou {
  width: 80vw;
  margin-top: 30vh;
  text-align: center;
  /* text-transform: uppercase; */
  /* font-family: Arial, Helvetica, sans-serif; */
  line-height: 160%;
}
