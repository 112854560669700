.appSubmit__style {
  background-color: rgb(130, 155, 173);
  height: 100vh;
  color: white;
  fill: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.backNextButtons {
  display: flex;
  font-family: Avenir;
}

.textStyle {
  width: 80vw;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: Avenir;
  line-height: 160%;
}

.uvsApp__nextButton {
  cursor: pointer;
}
