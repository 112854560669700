.containerCircus {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* row-gap: 6vw; */
  margin-bottom: 20vw;
}

.circusText {
  margin: 18vw;
  line-height: 6vw;
}

#flyer {
  width: 60%;
  margin-top: -8vw;
}

.getTickets {
  color: white;
  background-color: black;
  font-size: 3vw;
  height: 13vw;
  width: 25vw;
  font-weight: bold;
  margin-top: 4vw;
}
