.questFive__style {
  background-color: rgb(252, 228, 45);
  height: 100vh;
  fill: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.uvsApp__yesNo5 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uvsApp__yesNo5 > * {
  /* margin-right: 30px;
  margin-bottom: 30px; */
  font-family: Avenir;
}

.radioApplication5 {
  margin-bottom: 40px;
}

.textStyle5 {
  width: 80vw;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: Avenir;
  line-height: 160%;
}

.uvsApp__nextButton {
  cursor: pointer;
  font-family: Avenir;
}
