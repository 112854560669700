.you__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8vw;
}

.you__promise {
  background-color: #2b61d5;
  color: #f3f3f3;
  border-radius: 5.128vw;
  height: 10.513vw;
  width: 57.692vw;
  border: none;
  text-align: center;
  font-size: 3.59vw;
  font-family: "C90Degree";
  font-weight: 700;
  text-shadow: 0.1px 0.1px #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 9.159vw;
}
.you__question {
  width: 58vw;
  margin-top: 6vw;
}
.you__feedbackCircle {
  height: 67.949vw;
  width: 67.949vw;
  border-radius: 50%;
  border: 2.051vw solid #3b6052;
  background-color: #eea4c8;
  color: #f3f3f3;
  text-align: center;
  font-size: 3.833vw;
  line-height: 3.89vw;
  font-family: "C90Degree";
  font-weight: 700;
  /* text-shadow: 0.9px 0.9px #3b6052; */
  margin-bottom: 11.026vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feedbackCircle__submitButton {
  height: 2.564vw;
  width: 2.564vw;
  cursor: pointer;
  border-radius: 50%;
  background-color: #cf3e2c;
}
.you__feedbackCircle__textarea {
  width: 45vw;
  height: 19vw;
  border-radius: 2.5vw;
  border: 0.35vw solid #212941;
  margin-bottom: 1vw;
  font-size: 16px;
  font-family: "C90Degree";
  font-weight: 700;
  text-align: center;
  resize: none;
  padding-top: 3vw;
}
.you__feedbackCircleText {
  cursor: pointer;
}

#youCircleTwo {
  background-color: #a8adc0;
  border: none;
  width: 55vw;
  height: 55vw;
  margin-top: 7.692vw;
}

#youCircleTwo > p {
  margin-top: 11.418vw;
  width: 29.744vw;
}
.you__feedbackCircle__input {
  width: 30vw;
  height: 4.25vw;
  border-radius: 1.7vw;
  border: 0.25vw solid #212941;
  margin-bottom: 1vw;
  font-family: "C90Degree";
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.you__socialLinks {
  width: 19.231vw;
  height: 19.231vw;
  border-radius: 50%;
  color: #ed5d32;
  font-family: "C90Degree";
  font-weight: 700;
  font-size: 3.333vw;
  letter-spacing: 0.074vw;
  background-color: #95b2d5;
  margin-bottom: 2.564vw;
  display: flex;
  align-items: center;
  justify-content: center;
}
.you__socialLinksContainer > a {
  text-decoration: none;
}
#link2 {
  background-color: #f1b168;
}
#link3 {
  background-color: #242e4c;
}
.you_socialLinkText {
  text-align: center;
}

@media screen and (max-width: 238px) {
  #youCircleTwo {
    font-size: 2.5vw;
  }
}
@media screen and (min-width: 768px) {
  .you__socialLinksContainer {
    display: flex;
    column-gap: 8vw;
  }
  .you__socialLinks {
    width: 15.625vw;
    height: 15.625vw;
    font-size: 2.344vw;
  }
  .you__container {
    margin-top: unset;
  }
  .you__promise {
    margin-top: 2.5vw;
    width: 36.068vw;
    height: 6.641vw;
    font-size: 2.344vw;
    border-radius: 2.604vw;
    margin-bottom: 7.943vw;
  }
  .you__feedbackCircle {
    height: 38.542vw;
    width: 38.542vw;
    border: 2.604vw solid #3b6052;
    font-size: 1.953vw;
    margin-bottom: 7.813vw;
    line-height: unset;
  }
  .you__feedbackCircle__textarea {
    font-size: 32px;
    width: 26.823vw;
    height: 11.198vw;
  }
  .feedbackCircle__submitButton {
    width: 1.563vw;
    height: 1.563vw;
  }
  #youCircleTwo {
    margin-top: 5.599vw;
    width: 33.594vw;
    height: 33.594vw;
  }
  #youCircleTwo > p {
    margin-top: 8.818vw;
    width: 20.833vw;
  }
  .you__feedbackCircle__input {
    font-size: 32px;
    width: 25vw;
    height: 3.906vw;
  }
}
@media screen and (min-width: 1200px) {
  .you__promise {
    border-radius: 1.042vw;
    height: 3.385vw;
    width: 18.229vw;
    font-size: 1.302vw;
    font-family: "C90Degree";
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 7.708vw;
  }

  #you__largeScreenContainer {
    flex-direction: row;
    column-gap: 6.094vw;
  }

  .you__question {
    width: 58vw;
    margin-top: 4.844vw;
  }
  .you__feedbackCircle {
    order: 3;
    height: 26.042vw;
    width: 26.042vw;
    border: 1.042vw solid #3b6052;
    font-size: 1.406vw;
    line-height: 1.563vw;
    font-weight: 700;
    margin-bottom: 4vw;
  }
  .feedbackCircle__submitButton {
    height: 1.042vw;
    width: 1.042vw;
  }
  .you__feedbackCircle__textarea {
    width: 18.125vw;
    height: 6.104vw;
    border-radius: 0.521vw;
    border: 0.15vw solid #212941;
    margin-bottom: 0.5vw;
    font-size: 24px;
    padding-top: 1.5vw;
  }
  #youCircleTwo {
    order: 1;
    width: 16.667vw;
    height: 16.667vw;
    margin-top: unset;
  }

  #youCircleTwo > p {
    margin-top: 4.375vw;
    margin-bottom: 1.25vw;
    width: 14.563vw;
    font-size: 1.302vw;
    line-height: 1.406vw;
    letter-spacing: 0.029vw;
  }
  .you__feedbackCircle__input {
    width: 12.396vw;
    height: 1.979vw;
    border-radius: 0.8vw;
    border: 0.125vw solid #212941;
    margin-bottom: 0.5vw;
    font-size: 24px;
  }
  .you__socialLinksContainer {
    display: flex;
    flex-direction: column;
    order: 2;
  }
  .you__socialLinks {
    width: 6.25vw;
    height: 6.25vw;
    border-radius: 50%;
    font-size: 0.938vw;
    line-height: 1.042vw;
    margin-bottom: 1vw;
    letter-spacing: 0.021vw;
  }
  .you__socialLinksContainer > a {
    z-index: 1;
  }
}
