.questFour__style {
  background-color: rgb(224, 228, 170);
  height: 100vh;
  fill: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.uvsApp__yesNo {
  /* display: flex; */
  /* justify-content: space-evenly; */
}

/* .uvsApp__yesNo > * {
  margin-right: 30px;
  margin-bottom: 30px;
} */

.radioApplication {
  margin-bottom: 40px;
}

.textStyle4 {
  width: 80vw;
  text-align: center;
  font-family: Avenir;
  line-height: 160%;
}

.backNext {
  display: flex;
}

.uvsApp__nextButton {
  cursor: pointer;
  font-family: Avenir;
}

.appNav__back {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.appNav__continue {
  display: flex;
  flex-direction: column;
  align-items: center;
}
