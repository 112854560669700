.bPage__container {
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20.562vw;
}
.bPageLegend__container {
  display: flex;
  justify-content: center;
  column-gap: 5.128vw;
  margin-top: 5.385vw;
  padding-top: 1vw;
  padding-bottom: 2vw;
  position: sticky;
  top: 0;
  background-color: white;
  width: 100vw;
}
@media screen and (min-width: 768px) {
  .bPageGallery__container {
    display: flex;
    flex-wrap: wrap;
    gap: 0vw 3vw;
    justify-content: center;
  }
  .bPageLegend__container {
    column-gap: 6.51vw;
    margin-top: 2.344vw;
  }
  .bPage__container {
    margin-bottom: 14.199vw;
  }
}
@media screen and (min-width: 1200px) {
  .bPageGallery__container {
    display: flex;
    flex-wrap: wrap;
    gap: 0vw 5.99vw;
    justify-content: center;
    margin-top: -7vw;
  }
  .bPageLegend__container {
    column-gap: 8.438vw;
    margin-top: 5.99vw;
    margin-bottom: 8.833vw;
  }
  .bPage__container {
    margin-bottom: 13.125vw;
    margin-left: 5.99vw;
    margin-right: 5.99vw;
  }
}
