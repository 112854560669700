.questEight__style {
  background-color: rgb(65, 124, 55);
  height: 100vh;
  color: white;
  fill: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
.backNextButtons8 {
  display: flex;
  font-family: Avenir;
}

.questEight__style > input {
  font-size: 16px;
  font-family: Avenir;
}

.textStyle8 {
  width: 80vw;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: Avenir;
  line-height: 160%;
}

.uvsApp__nextButton {
  cursor: pointer;
  font-family: Avenir;
}
