.links {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 2vw;
  margin: 16vw;
}

.links > a > button {
  width: 60vw;
  height: 10vw;
  font-size: 2.8vw;
  font-weight: 900;
  font-family: C90Degree;
  border-radius: 3.5vw;
  border: none;
  background-color: rgb(241, 142, 202);
  color: black;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .links {
    row-gap: 5vw;
  }
  .links > a > button {
    text-shadow: 0 0.1vw black;
    width: 90vw;
    height: 30vw;
    font-size: 5.8vw;
  }
}
