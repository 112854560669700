.pageC__circle {
  height: 26.154vw;
  width: 26.154vw;
  background-color: #d0cdcd;
  border-radius: 50%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pageC__circleText {
  color: #16463b;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
  font-size: 1.538vw;
  width: 14vw;
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 2vw;
}
.circleText__textOne {
  font-style: italic;
  font-weight: 700;
}

.pageC__circleLegend {
  height: 2.051vw;
  width: 2.051vw;
  border-radius: 50%;
}
.pageC__legendContainer {
  display: flex;
  gap: 1.795vw;
  margin-top: 3.077vw;
}
.pageC__legendBottomContainer {
  display: flex;
  gap: 0vw;
  margin-top: 3.077vw;
}
.pageC__legendBottomContainer > a,
svg {
  transform: scale(0.8);
}
@media screen and (min-width: 768px) {
  .pageC__circle {
    width: 10.547vw;
    height: 10.547vw;
  }
  .pageC__circleText {
    font-size: 0.781vw;
    width: 8.073vw;
    gap: 0.4vw;
  }
  .pageC__circleLegend {
    height: 0.781vw;
    width: 0.781vw;
  }
  .pageC__legendContainer {
    margin-top: 1.042vw;
    gap: 0.911vw;
  }
  .pageC__legendBottomContainer {
    margin-top: 1.042vw;
  }
}
@media screen and (min-width: 1200px) {
  .pageC__circle {
    width: 13.021vw;
    height: 13.021vw;
  }
  .pageC__circleText {
    font-size: 0.833vw;
    width: 8.073vw;
    gap: 0.4vw;
  }
  .pageC__circleLegend {
    height: 1.042vw;
    width: 1.042vw;
  }
  .pageC__legendContainer {
    margin-top: 1.354vw;
    gap: 1.042vw;
  }
  .pageC__legendBottomContainer {
    margin-top: 1.354vw;
    gap: 1.042vw;
  }
  .pageC__legendBottomContainer > a,
  svg {
    transform: unset;
  }
}
