.dPage__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 37.692vw;
}
.dPage__circleContainer {
  display: flex;
  flex-direction: column;
  row-gap: 15.385vw;
  margin-top: 7.949vw;
  align-items: center;
}
.dPage__circleRows {
  display: flex;
  justify-content: center;
  /* background-color: blue; */
}
.circleFirst {
  /* background-color: pink; */
  height: 15.513vw;
  margin-top: 2.5vw;
  margin-bottom: 2.5vw;
}
.circleFirstRight {
  /* background-color: lightblue; */
  height: 15.513vw;
  margin-top: 2.5vw;
  margin-bottom: 2.5vw;
}
.circleComingSoonSolo {
  height: 15.513vw;
  margin-top: 2.5vw;
  margin-bottom: 2.5vw;
  /* background-color: red; */
}
.circleSecond {
  /* background-color: lightgreen; */
  height: 20.513vw;
  width: 75vw;
  display: none;
  margin-right: -5vw;
}
.circleSecondGroupTwo {
  /* background-color: purple; */
  height: 20.513vw;
  width: 22vw;
  margin-right: 48vw;
  margin-left: -3vw;
  display: none;
}
.circleSecondGroupThree {
  /* background-color: orange; */
  height: 20.513vw;
  width: 25vw;
  margin-right: 9vw;
  margin-left: -5vw;
}
.comingSoonContainer {
  display: flex;
  align-items: center;
  /* background-color: aqua; */
}
/* the nth-child's below are for the pink circles and the non nth's are for the gold */
.circleSecondGroupThree:nth-child(2) {
  /* background-color: green; */
  height: 20.513vw;
  width: 25vw;
  margin-right: -3vw;
  margin-left: -3vw;
}
.comingSoon:nth-child(1) {
  width: 25vw;
  margin-left: 12vw;
  /* background-color: brown; */
}

#dCircle6,
#dCircle7 {
  display: none;
}
.comingSoon {
  width: 25vw;
  margin-right: 14vw;
  /* background-color: yellow; */
}
@media screen and (min-width: 768px) {
  .dPage__container {
    margin-bottom: 21.875vw;
  }
}
@media screen and (min-width: 1200px) {
  .circleFirst {
    height: 15.513vw;
    margin-left: unset;
  }
  .circleSecond {
    margin-right: -4vw;
  }
  .circleSecondGroupTwo {
    height: 20.513vw;
    margin-right: 54vw;
    margin-left: unset;
  }
  .circleSecondGroupThree {
    margin-right: 10vw;
    margin-left: -1vw;
  }
  .comingSoon {
    margin-right: 18vw;
  }

  /* the nth-child's below are for the pink circles and the non nth's are for the gold */
  .circleSecondGroupThree:nth-child(2) {
    margin-right: -3vw;
    margin-left: 8vw;
  }
  .comingSoon:nth-child(1) {
    margin-left: 15vw;
    margin-right: 5vw;
  }
}
