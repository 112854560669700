.questSix__style {
  background-color: rgb(75, 127, 88);
  min-height: 100vh;
  color: white;
  fill: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.backNextButtons {
  display: flex;
}

.textStyle6 {
  width: 80vw;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: Avenir;
  line-height: 160%;
}

.questSix__style > input {
  font-size: 16px;
  font-family: Avenir;
}

.uvsApp__nextButton {
  cursor: pointer;
  font-family: Avenir;
}
