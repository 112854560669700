.questThree__style {
  background-color: rgb(32, 56, 72);
  height: 100vh;
  color: white;
  fill: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.uvsApp__yesNo {
  display: flex;
  align-items: center;
  column-gap: 4vw;
  margin-bottom: 30px;
}

.uvsApp__yesNo > * {
  /* margin-right: 30px;
  margin-bottom: 30px; */
  font-family: Avenir;
  display: flex;
}

.yes {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.no {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textStyle3 {
  width: 80vw;
  text-align: center;
  /* text-transform: uppercase; */
  font-family: Avenir;
  line-height: 160%;
}

.nextDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uvsApp__nextButton {
  cursor: pointer;
  font-family: Avenir;
}
